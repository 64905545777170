import { TYPE } from 'Config';
import ArtistPage from 'pages/artist/ArtistPage';
import NotFoundPage from 'pages/errors/NotFoundPage';
import FanPage from 'pages/fan/FanPage';
import LegalNoticePage from 'pages/legalNotice/LegalNoticePage';
import PolicyPage from 'pages/policy/PolicyPage';
import ContestRules from 'pages/contestRules/ContestRulesPage';
import { Navigate, Route, Routes, HashRouter } from 'react-router-dom';

const Router = () => {
    return (
        <HashRouter>
            <Routes>
                {TYPE === 'artys' && (
                    <>
                        <Route path="/" element={<FanPage />} />
                        <Route path="/privacy-policy" element={<PolicyPage type="fan" />} />
                        <Route path="/contest-rules" element={<ContestRules type="fan" />} />
                        <Route path="/legal-terms" element={<LegalNoticePage type="fan" />} />
                    </>
                )}
                {TYPE === 'umatch' && (
                    <>
                        <Route path="/" element={<ArtistPage />} />
                        <Route path="/estimate" element={<ArtistPage />}/>
                        <Route path="/estimate/ambassador" element={<ArtistPage />}/>
                        <Route path="/estimate/professional" element={<ArtistPage />}/>
                        <Route path="/privacy-policy" element={<PolicyPage type="artist" />} />
                        <Route path="/contest-rules" element={<ContestRules type="artist" />} />
                        <Route path="/legal-terms" element={<LegalNoticePage type="artist" />} />
                    </>
                )}

                <Route path="/404" element={<NotFoundPage />} />
                <Route path="*" element={<Navigate replace={true} to="/404" />} />
            </Routes>
        </HashRouter>
    );
};

export default Router;
