import { useTranslation } from 'react-i18next';

interface Props {
    type?: 'normal' | 'light';
}

const ArtysLogo = ({ type = 'normal' }: Props) => {
    const { t } = useTranslation();
    return (
        <a href="/">
            <img src={t(type === 'normal' ? 'logo.normal_url' : 'logo.light_url', { interpolation: { escapeValue: true } }) ?? '#'} />
        </a>
    );
};

export default ArtysLogo;
