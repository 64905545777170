import Container from 'common/components/container/Container';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Sponsors.module.scss';

export interface Sponsor {
    sponsor: string;
}

interface Props {
    colorMode: 'light' | 'dark';
}

const Technology: React.FC<Props> = ({ colorMode }: Props) => {
    const { t } = useTranslation();
    const sponsors: Sponsor[] = t('home.sponsors', { returnObjects: true }) ?? [];

    return (
        <div className={`${colorMode === 'light' ? styles.backgroundLight : styles.backgroundDark}`} >
            <Container>
                <div className={styles.flexRow}>
                    {sponsors.map((item, index) => {
                        return (
                            <img key={index} src={item.sponsor} className={styles.sponsorLogo}></img>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default Technology;
