import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'common/components/footer/Footer';
import UMatchLogo from 'common/components/logo/UMatchLogo';
import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import styles from './ContestRulesPage.module.scss';
import ScrollToTop from 'common/components/scrollToTop/ScrollToTop';
import useTitle from 'common/hooks/useTitle';
import ArtysLogo from 'common/components/logo/ArtysLogo';
import HeaderWaves from 'common/components/waves/HeaderWaves';
import PHtml from 'common/components/divHtml/PHtml';

interface TranslationText {
    title: string;
    content: string;
    subtitles?: TranslationText[];
}

interface Props {
    type: 'artist' | 'fan';
}

const ContestRulesPage: React.FC<Props> = ({ type }) => {
    const { t } = useTranslation();
    useTitle(t('contest_rules.page_title'));

    const texts: TranslationText[] = t('contest_rules.texts', { returnObjects: true }) ?? [];

    return (
        <div className={`${styles.container} ${type === 'fan' ? styles.containerDark : undefined}`}>
            <ScrollToTop />

            {type === 'fan' && <HeaderWaves
                wavesUrl={t('home.header.waves_url', { interpolation: { escapeValue: true } })}
                wavesMobileUrl={t('home.header.waves_mobile_url', { interpolation: { escapeValue: true } })}
            />}

            <Container>
                <div className={styles.logoContainer}>
                    <UMatchLogo />
                </div>

                <PageSection
                    title={t('contest_rules.title')}
                    className={styles.section}
                    contentClassName={styles.content}
                    colorMode={type === 'fan' ? 'dark' : 'light'}
                >
                    {texts.map((t, i) => (
                        <div key={i} className={type === 'fan' ? styles.darkItem : styles.lightItem}>
                            <h1>{t.title}</h1>
                            <PHtml html={t.content} />
                            {(t.subtitles ?? []).map((st, ii) => (
                                <div key={ii}>
                                    <h2 className={styles.h2}>{st.title}</h2>
                                    <PHtml html={st.content} />
                                </div>
                            ))}
                        </div>
                    ))}
                </PageSection>
            </Container>

            {type === 'fan'
                ? (
                    <Footer
                        colorMode="dark"
                        logo={<ArtysLogo type="light" />}
                    />
                )
                : (
                    <Footer
                        colorMode="light"
                        logo={type === 'artist' ? <UMatchLogo type="light" /> : <ArtysLogo type="light" />}
                    />
                )}
        </div>
    );
};

export default ContestRulesPage;
