import React from 'react';
import styles from './Label.module.scss';

type Props = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
    className?: string;
};

const Label: React.FC<Props> = ({ className, ...props }: Props) => (
    <label {...props} className={`${styles.label} ${className ?? ''}`}>
        {props.children}
    </label>
);

export default Label;
