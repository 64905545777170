import numbro from 'numbro';

export default function formatCurrency (value: number) {
    numbro.languageData().delimiters.thousands = ',';
    numbro.languageData().delimiters.thousandsSize = 3;
    numbro.languageData().delimiters.decimal = '.';

    return numbro(value || 0).formatCurrency({
        thousandSeparated: true,
        mantissa: 2,
        // optionalMantissa: true,
        currencySymbol: '',
        currencyPosition: 'postfix',
        spaceSeparatedCurrency: true,
        optionalMantissa: true
    });
}
