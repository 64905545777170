import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HowItWorks.module.scss';

const HowItWorks: React.FC = () => {
    const { t } = useTranslation();

    const url = `${t('home.how_it_works.youtube_url')}`;

    return (
        <Container>
            <PageSection title={t('home.how_it_works.title')} className={styles.container} contentClassName={styles.content}>
                <iframe
                    src={url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    className={styles.video}
                />
                <div className={styles.description}>
                    {t('home.how_it_works.description')}
                </div>
            </PageSection>
        </Container>
    );
};

export default HowItWorks;
