import React from 'react';
import { Flyout, VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryLabel, VictoryStack, VictoryTheme, VictoryTooltip } from 'victory';
import ArtistResultGraphBox from '../artistResultGraphBox/ArtistResultGraphBox';
import { getArtistGrapthData, getArtysMonthlyRoyalties, getMonthlyRecords, getMonthlyRoyaltips, getSpotifyMonthlyRoyalties, type RecordType } from '../../calculator/ArtistIncomeCalculator';
import colors from 'styles/export/colors.module.scss';
import { useTranslation } from 'react-i18next';
import styles from './ArtistResultGraph.module.scss';
import formatCurrency from 'common/utils/formatCurrency';
import useIsMobile from 'common/hooks/useIsMobile';

function findLastIndex (array: any[], predicate: any) {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array)) {
            return l;
        }
    }
    return -1;
}

const BarLabel = (props: any) => {
    if (!props.text(props)) {
        return null;
    }
    return (
        <VictoryTooltip
            {...props}
            cornerRadius={6}
            active={true}
            flyoutPadding={{ top: 16, bottom: 16, left: 18, right: 18 }}
            labelComponent={(
                <VictoryLabel
                    style={{
                        fill: colors.basicWhite,
                        userSelect: 'none',
                        fontSize: 14,
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                    }}
                />
            )}
            flyoutStyle={{
                fill: props.datum.x === 'spotify' ? colors.basicBlack : colors.primaryGreen,
                strokeWidth: 0
            }}
            flyoutComponent={<Flyout pointerWidth={30} />}
        />
    );
};

interface Props {
    fanbaseSize: number;
    streamsPerMonth: number;
    engagementRatePercentage: number;
    recordType: RecordType;
}

const ArtistResultGraph: React.FC<Props> = ({
    fanbaseSize,
    streamsPerMonth,
    engagementRatePercentage,
    recordType
}) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const graphData = getArtistGrapthData(
        fanbaseSize,
        streamsPerMonth,
        engagementRatePercentage,
    );

    const royaltiesValue = getArtysMonthlyRoyalties(streamsPerMonth);
    const royaltipsValue = getMonthlyRoyaltips(fanbaseSize, engagementRatePercentage);
    const recordsValue = getMonthlyRecords(fanbaseSize, engagementRatePercentage, recordType);
    const total = royaltiesValue + royaltipsValue + recordsValue;

    const totalSpotify = getSpotifyMonthlyRoyalties(streamsPerMonth);

    function getTopStackForX (xVal: number) {
        return (
            findLastIndex(graphData, (stackArr: any) =>
                stackArr.some((bar: any) => bar.x === xVal && bar.y > 0)
            ) + 1
        );
    }
    function isLastBarSegment (barData: any) {
        return barData._stack === getTopStackForX(barData.xName);
    }

    const width = isMobile ? 300 : 800;
    const height = isMobile ? 340 : 420;
    const domainPaddingX = isMobile ? 0 : 100;
    const barWidth = isMobile ? 34 : 44;
    const barCornerRadius = barWidth / 2;

    return (
        <div className={styles.container}>

            <div className={styles.graph}>
                <VictoryChart
                    domainPadding={{ x: domainPaddingX }}
                    width={width}
                    height={height}
                    theme={VictoryTheme.material}
                    containerComponent={
                        <VictoryContainer
                            style={{
                                pointerEvents: 'auto',
                                userSelect: 'auto',
                                touchAction: 'auto'
                            }}
                        />
                    }
                    padding={ !isMobile ? { left: 75, top: 50, bottom: 50, right: 50 } : { left: 40, top: 50, bottom: 50, right: 45 }}
                >
                    <VictoryAxis
                        style={!isMobile
                            ? {
                                tickLabels: {
                                    fill: colors.resultGraphAxis,
                                },
                                grid: {
                                    strokeWidth: 0,
                                },
                                axis: {
                                    stroke: colors.resultGraphAxis,
                                    strokeWidth: 1,
                                },
                                ticks: {
                                    stroke: colors.resultGraphAxis,
                                },
                            }
                            : {
                                axis: { stroke: 'transparent' },
                                ticks: { stroke: 'transparent' },
                                grid: {
                                    strokeWidth: 0,
                                },
                                tickLabels: { fontSize: 11 }
                            }
                        }
                        tickFormat={(x) => {
                            const label = t('home.estimate_income.artist.graph.' + x);
                            return !isMobile ? label : label.replace(/ /g, '\n');
                        }}
                    />
                    <VictoryAxis
                        dependentAxis
                        tickFormat={(tick) => `${tick}`}
                        crossAxis={false}
                        tickCount={10}
                        style={!isMobile
                            ? {
                                tickLabels: {
                                    fill: colors.resultGraphAxis,
                                },
                                grid: {
                                    strokeWidth: 0,
                                },
                                axis: {
                                    stroke: colors.resultGraphAxis,
                                    strokeWidth: 1,
                                },
                                ticks: {
                                    stroke: colors.resultGraphAxis,
                                },
                            }
                            : {
                                axis: { stroke: 'transparent' },
                                ticks: { stroke: 'transparent' },
                                tickLabels: { fill: 'transparent' },
                                grid: {
                                    strokeWidth: 0,
                                },
                            }}
                    />
                    <VictoryStack
                        colorScale={[
                            colors.basicBlack,
                            colors.primaryPurple,
                            colors.primaryYellow,
                        ]}
                    >
                        {graphData.map((data, i) => {
                            return <VictoryBar
                                data={data}
                                key={i}
                                barWidth={barWidth}
                                cornerRadius={{
                                    top: (barData) => {
                                        return isLastBarSegment(barData.datum) ? barCornerRadius : 0;
                                    }
                                }}
                                style={{
                                    data: {
                                        fill: (e) => {
                                            if (e.datum.x === recordType || (e.datum.x === 'artys' && recordType === 'none')) {
                                                return e.datum.fill;
                                            }
                                            return e.datum.fillDisabled;
                                        }
                                    }
                                }}
                                // labelComponent={<VictoryTooltip />}
                                labelComponent={<BarLabel />}
                                labels={(e) => {
                                    if (!isLastBarSegment(e.datum)) {
                                        return null;
                                    }

                                    if (e.datum.x === 'spotify') {
                                        return formatCurrency(totalSpotify);
                                    }

                                    if (e.datum.x === recordType || (e.datum.x === 'artys' && recordType === 'none')) {
                                        return formatCurrency(total);
                                    }

                                    return null;
                                }}
                            />;
                        })}
                    </VictoryStack>
                </VictoryChart>
            </div>

            <div className={styles.values}>
                <ArtistResultGraphBox
                    color={colors.basicBlack}
                    name={t('home.estimate_income.artist.result.royalties')}
                    value={royaltiesValue}
                    valuePer={t('home.estimate_income.artist.result.value_per')}
                    valueUnit={t('home.estimate_income.currency')}
                />
                <ArtistResultGraphBox
                    color={colors.primaryPurple}
                    name={t('home.estimate_income.artist.result.royaltips')}
                    value={royaltipsValue}
                    valuePer={t('home.estimate_income.artist.result.value_per')}
                    valueUnit={t('home.estimate_income.currency')}
                />
                <ArtistResultGraphBox
                    color={colors.primaryYellow}
                    name={t('home.estimate_income.artist.result.records')}
                    value={recordsValue}
                    valuePer={t('home.estimate_income.artist.result.value_per')}
                    valueUnit={t('home.estimate_income.currency')}
                />
            </div>
        </div>
    );
};

export default ArtistResultGraph;
