import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import Selector from 'common/components/selector/Selector';
import { type Data } from 'common/components/selector/SelectorModel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DiscoverUMatch.module.scss';

const DiscoverUMatch: React.FC = () => {
    const { t } = useTranslation();
    const data: Data = t('home.discover_umatch', { returnObjects: true }) ?? [];

    return (
        <Container>
            <PageSection
                title={data.title}
                contentClassName={styles.content}
            >
                <Selector data={data} colorMode={'light'}></Selector>
            </PageSection>
        </Container>
    );
};

export default DiscoverUMatch;
