import colors from 'styles/export/colors.module.scss';

export type RecordType = 'none' | 'gold' | 'platinum' | 'diamond';

const subscription = 9.99;
const royaltipsRatePercentage = 30;

const goldBonusPercentage = 3;
const platinumBonusPercentage = 6;
const diamondBonusPercentage = 9;

const incomePerStreamOnSpotify = 0.0044;
const incomePerStreamOnArtys = 0.0066;

const percentage = (amount: number) => amount / 100;
const round = Math.round;

export const getArtysMonthlyRoyalties = (streamsPerMonth: number) => {
    return round(streamsPerMonth * incomePerStreamOnArtys);
};

export const getSpotifyMonthlyRoyalties = (streamsPerMonth: number) => {
    return round(streamsPerMonth * incomePerStreamOnSpotify);
};

const getSuperfansEngaged = (fanbaseSize: number, engagementRatePercentage: number) => {
    return fanbaseSize * percentage(engagementRatePercentage);
};

export const getMonthlyRoyaltips = (fanbaseSize: number, engagementRatePercentage: number) => {
    return round(subscription * percentage(royaltipsRatePercentage) * getSuperfansEngaged(fanbaseSize, engagementRatePercentage));
};

export const getMonthlyRecords = (
    fanbaseSize: number,
    engagementRatePercentage: number,
    recordType: RecordType,
) => {
    switch (recordType) {
        case 'gold':
            return round(subscription * percentage(goldBonusPercentage) * getSuperfansEngaged(fanbaseSize, engagementRatePercentage));
        case 'platinum':
            return round(subscription * percentage(platinumBonusPercentage) * getSuperfansEngaged(fanbaseSize, engagementRatePercentage));
        case 'diamond':
            return round(subscription * percentage(diamondBonusPercentage) * getSuperfansEngaged(fanbaseSize, engagementRatePercentage));
        default:
            return 0;
    }
};

export const getArtistValue = (
    fanbaseSize: number,
    streamsPerMonth: number,
    engagementRatePercentage: number,
    recordType: RecordType,
) => {
    return getArtysMonthlyRoyalties(streamsPerMonth) + getMonthlyRoyaltips(fanbaseSize, engagementRatePercentage) + getMonthlyRecords(fanbaseSize, engagementRatePercentage, recordType);
};

type ArtistGraphValueX = 'spotify' | 'artys' | RecordType;

interface ArtistGraphValue {
    x: ArtistGraphValueX;
    y: number;
    fill: string;
    fillDisabled: string;
}

export const getArtistGrapthData = (
    fanbaseSize: number,
    streamsPerMonth: number,
    engagementRatePercentage: number,
): ArtistGraphValue[][] => {
    const royaltiesColor = colors.basicBlack;
    const royaltipsColor = colors.primaryPurple;
    const recordsColor = colors.primaryYellow;

    const royaltiesDisabledColor = colors.resultGraphAxis;
    const royaltipsDisabledColor = colors.basicGrayDarker;
    const recordsDisabledColor = colors.resultGraphRecordsDisabled;

    return [
        // Royalties
        [
            {
                x: 'spotify',
                y: getSpotifyMonthlyRoyalties(streamsPerMonth),
                fill: royaltiesColor,
                fillDisabled: royaltiesColor, // Special one
            },
            {
                x: 'artys',
                y: getArtysMonthlyRoyalties(streamsPerMonth),
                fill: royaltiesColor,
                fillDisabled: royaltiesDisabledColor
            },
            {
                x: 'gold',
                y: getArtysMonthlyRoyalties(streamsPerMonth),
                fill: royaltiesColor,
                fillDisabled: royaltiesDisabledColor
            },
            {
                x: 'platinum',
                y: getArtysMonthlyRoyalties(streamsPerMonth),
                fill: royaltiesColor,
                fillDisabled: royaltiesDisabledColor
            },
            {
                x: 'diamond',
                y: getArtysMonthlyRoyalties(streamsPerMonth),
                fill: royaltiesColor,
                fillDisabled: royaltiesDisabledColor
            },
        ],
        // Royaltips
        [
            {
                x: 'spotify',
                y: 0,
                fill: royaltipsColor,
                fillDisabled: royaltipsDisabledColor,
            },
            {
                x: 'artys',
                y: getMonthlyRoyaltips(fanbaseSize, engagementRatePercentage),
                fill: royaltipsColor,
                fillDisabled: royaltipsDisabledColor,
            },
            {
                x: 'gold',
                y: getMonthlyRoyaltips(fanbaseSize, engagementRatePercentage),
                fill: royaltipsColor,
                fillDisabled: royaltipsDisabledColor,
            },
            {
                x: 'platinum',
                y: getMonthlyRoyaltips(fanbaseSize, engagementRatePercentage),
                fill: royaltipsColor,
                fillDisabled: royaltipsDisabledColor,
            },
            {
                x: 'diamond',
                y: getMonthlyRoyaltips(fanbaseSize, engagementRatePercentage),
                fill: royaltipsColor,
                fillDisabled: royaltipsDisabledColor,
            },
        ],
        // Records
        [
            {
                x: 'spotify',
                y: 0,
                fill: recordsColor,
                fillDisabled: recordsDisabledColor,
            },
            {
                x: 'artys',
                y: 0,
                fill: recordsColor,
                fillDisabled: recordsDisabledColor,
            },
            {
                x: 'gold',
                y: getMonthlyRecords(fanbaseSize, engagementRatePercentage, 'gold'),
                fill: recordsColor,
                fillDisabled: recordsDisabledColor,
            },
            {
                x: 'platinum',
                y: getMonthlyRecords(fanbaseSize, engagementRatePercentage, 'platinum'),
                fill: recordsColor,
                fillDisabled: recordsDisabledColor,
            },
            {
                x: 'diamond',
                y: getMonthlyRecords(fanbaseSize, engagementRatePercentage, 'diamond'),
                fill: recordsColor,
                fillDisabled: recordsDisabledColor,
            },
        ],
    ];
};
