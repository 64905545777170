import Button from 'common/components/button/Button';
import React, { useContext } from 'react';
import colors from 'styles/export/colors.module.scss';
import useIsMobile from 'common/hooks/useIsMobile';
import { SubscribeWaitListModalContext } from 'common/components/subscribeWaitListModal/SubscribeWaitListModal';
import SelectorWeb from './SelectorWeb';
import SelectorMobile from './SelectorMobile';
import { type Data } from './SelectorModel';

interface Props {
    data: Data;
    colorMode: 'light' | 'dark';
}

const Selector: React.FC<Props> = ({ data, colorMode }: Props) => {
    const isMobile = useIsMobile();
    const { open: openSubscribeWaitListModal } = useContext(SubscribeWaitListModalContext);

    return (
        <>
            {!isMobile && (
                <SelectorWeb data={data} colorMode={colorMode} />
            )}
            {isMobile && (
                <SelectorMobile data={data} colorMode={colorMode} />
            )}
            <div>
                <Button
                    color={colorMode === 'light' ? colors.primaryGreen : colors.primaryYellow}
                    textColor={colors.basicWhite}
                    onClick={() => {
                        openSubscribeWaitListModal(colorMode);
                    }}
                >
                    {data.signup_button.toUpperCase()}
                </Button>
            </div>
        </>
    );
};

export default Selector;
