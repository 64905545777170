import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import MoneyFormat from '../MoneyFormat/MoneyFormat';
import Slider from 'rc-slider';
import styles from './RangeSlider.module.scss';
import Logarithmic from './Logarithmic';
import Linear from './Linear';

export interface SliderValue {
    position: number;
    value: number;
}

interface Props {
    value: number;
    minPos?: number;
    maxPos?: number;
    min: number;
    minLabel?: string;
    max: number;
    maxLabel?: string;
    step?: number;
    showMinMaxLabels?: boolean;
    showValueLabel?: boolean;
    valueLabelSuffix?: string;
    onChange: (v: number) => void;
};

const RangeSlider = ({ min, max, minLabel, maxLabel, value, showMinMaxLabels, showValueLabel, onChange, valueLabelSuffix, minPos = 0, maxPos = 100, step }: Props) => {
    const calculator = !step ? new Logarithmic({ minPos, maxPos, minVal: min, maxVal: max }) : new Linear();

    const [tempValue, setTempValue] = useState<SliderValue>({
        position: calculator.position(value),
        value,
    });
    const debounced = useDebouncedCallback(
        (v) => {
            onChange(v);
        },
        100
    );

    const calculateValue = (pos: number) => {
        if (pos === 0) {
            return 0;
        }

        const val = calculator.value(pos);

        if (val > 1000) return Math.round(val / 100) * 100;
        if (val > 500) return Math.round(val / 10) * 10;

        return Math.round(val);
    };

    const handlePositionChange = (v: number) => {
        let pos = v;
        if (pos <= minPos) {
            pos = minPos;
        }
        if (pos >= maxPos) {
            pos = maxPos;
        }

        const newValue: SliderValue = {
            position: pos,
            value: calculateValue(pos),
        };

        setTempValue(newValue);
        debounced(newValue.value);
    };

    const handleValueChange = (v: number) => {
        let val = v;
        if (val <= min) {
            val = min;
        }
        if (val >= max) {
            val = max;
        }

        const newValue: SliderValue = {
            position: calculator.position(val),
            value: val,
        };

        setTempValue(newValue);
        debounced(newValue.value);
    };

    useNoInitialEffect(() => {
        if (value !== tempValue.value) {
            setTempValue({
                value,
                position: calculator.position(value),
            });
        }
    }, [value]);

    return (
        <div className={styles.container}>
            <div className={styles.rowContainer}>
                <div className={styles.sliderContainer}>
                    <Slider
                        value={tempValue.position}
                        onChange={(e) => {
                            handlePositionChange(e as number);
                        }}
                        min={minPos}
                        max={maxPos}
                        className={styles.slider}
                        step={step ?? undefined}
                    />
                </div>
                {showMinMaxLabels && (
                    <div>
                        <span className={styles.minLabel}>{minLabel}</span>
                        <span className={styles.maxLabel}>{maxLabel}</span>
                    </div>
                )}
            </div>
            {showValueLabel && (
                <MoneyFormat
                    className={styles.valueContainer}
                    value={tempValue.value}
                    displayType="input"
                    suffix={valueLabelSuffix}
                    minLength={min}
                    maxLength={max}
                    fixedDecimalScale={ !!valueLabelSuffix}
                    type="text"
                    onValueChange={(e) => {
                        handleValueChange(e.floatValue ?? 0);
                    }}
                    isAllowed={(e) => (e.floatValue ?? 0) >= min && (e.floatValue ?? 0) <= max}
                />
            )}
        </div>
    );
};

export default RangeSlider;
