import Container from 'common/components/container/Container';
import DivHtml from 'common/components/divHtml/DivHtml';
import PageSection from 'common/components/pageSection/PageSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Technology.module.scss';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';

interface Props {
    colorMode: 'light' | 'dark';
}

const Technology: React.FC<Props> = ({ colorMode }) => {
    const { t } = useTranslation();
    const isLightMode = colorMode === 'light';

    return (
        <Container>
            <PageSection title={t('home.technology.title')} colorMode={colorMode}>
                <div className={styles.technology_mainDiv}>
                    <div className={styles.technology_leftDiv}>
                        <img src={`${t('home.technology.image')}`}
                            className={styles.technology_image}
                        />
                    </div>

                    <div className={styles.technology_rightDiv}>
                        <DivHtml
                            html={t('home.technology.text', { interpolation: { escapeValue: false } })}
                            className={`${styles.technology_description} ${isLightMode ? styles.technology_descriptionLight : styles.technology_descriptionDark}`}
                        />
                        <Button
                            color={isLightMode ? colors.primaryGreen : colors.primaryYellow}
                            textColor={colors.basicWhite}
                            onClick={() => { window.open(`${t('home.technology.button_url')}`); }}
                        >
                            {t('home.technology.button').toUpperCase()}
                        </Button>
                    </div>

                </div>

            </PageSection>
        </Container>
    );
};

export default Technology;
