import MoneyFormat from 'common/components/MoneyFormat/MoneyFormat';
import React from 'react';
import styles from './ArtistResultGraphBox.module.scss';

interface Props {
    color: string;
    name: string;
    value: number;
    valueUnit: string;
    valuePer: string;
}

const ArtistResultGraphBox: React.FC<Props> = ({ color, name, value, valueUnit, valuePer }) => {
    return (
        <div className={styles.container}>
            <div>
                <span className={styles.circle} style={{ backgroundColor: color }} />
                <span className={styles.name}>{name}</span>
            </div>
            <div className={styles.priceContainer}>
                <span className={styles.priceValue} style={{ color }}><MoneyFormat value={value} /></span>
                <span className={styles.priceUnit} style={{ color }}>{valueUnit}</span>
                <span className={styles.pricePer}>{valuePer}</span>
            </div>
        </div>
    );
};

export default ArtistResultGraphBox;
