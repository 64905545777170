import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IncomeProfessionalTab.module.scss';
import RangeSlider from 'common/components/rangeSlider/RangeSlider';
import Label from 'common/components/label/Label';
import FormGrid from '../formGrid/FormGrid';
import { getProfessionalValue } from '../calculator/ProfessionalIncomeCalculator';
import DivHtml from 'common/components/divHtml/DivHtml';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import { SubscribeWaitListModalContext } from 'common/components/subscribeWaitListModal/SubscribeWaitListModal';

interface FormModel {
    fanbaseSize: number;
    engagementRatePercentage: number;
}

const IncomeProfessionalTab: React.FC = () => {
    const { t } = useTranslation();
    const [model, setModel] = useState<FormModel>({
        fanbaseSize: 36000,
        engagementRatePercentage: 5,
    });
    const { open: openSubscribeWaitListModal } = useContext(SubscribeWaitListModalContext);
    const resultValue = getProfessionalValue(model.fanbaseSize, model.engagementRatePercentage);

    const onClickSignUp = () => {
        openSubscribeWaitListModal('light');
    };

    return (
        <div>
            <FormGrid
                value={resultValue}
                valueUnit={t('home.estimate_income.currency')}
                valuePer={t('home.estimate_income.professional.royaltips.value_per')}
                infoText={t('home.estimate_income.professional.royaltips.info_text')}
            >
                <div>
                    <DivHtml className={styles.usageNote} html={t('home.estimate_income.artist.labels.usage_note', { interpolation: { escapeValue: true } })} />
                    <Label>{t('home.estimate_income.professional.labels.fanbase_size')}</Label>
                    <RangeSlider
                        onChange={(v) => setModel((m) => ({ ...m, fanbaseSize: v }))}
                        value={model.fanbaseSize}
                        minPos={0}
                        maxPos={100}
                        min={0}
                        max={100000000}
                        minLabel="0"
                        maxLabel="100M"
                        showMinMaxLabels={true}
                        showValueLabel={true}
                    />
                    <Label>{t('home.estimate_income.professional.labels.engagement_rate')}</Label>
                    <RangeSlider
                        onChange={(v) => setModel((m) => ({ ...m, engagementRatePercentage: v }))}
                        value={model.engagementRatePercentage}
                        minPos={0}
                        maxPos={100}
                        min={0}
                        max={100}
                        minLabel="0"
                        maxLabel="100%"
                        showMinMaxLabels={true}
                        showValueLabel={true}
                        valueLabelSuffix="%"
                    />
                </div>
            </FormGrid>

            <div className={styles.program}>
                <div className={styles.title}>{t('home.estimate_income.professional.program.title')}</div>
                <DivHtml className={styles.text} html={t('home.estimate_income.professional.program.text', { interpolation: { escapeValue: false } })} />

                <Button color={colors.primaryGreen} onClick={onClickSignUp}>
                    {t('home.estimate_income.professional.program.signup_button').toUpperCase()}
                </Button>
            </div>
        </div>
    );
};

export default IncomeProfessionalTab;
