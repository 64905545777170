const subscription = 9.99;
const royaltipsRatePercentage = 25;

const percentage = (amount: number) => amount / 100;
const round = Math.round;

const getSuperfansEngaged = (fanbaseSize: number, engagementRatePercentage: number) => {
    return fanbaseSize * percentage(engagementRatePercentage);
};

const getMonthlyRoyaltips = (fanbaseSize: number, engagementRatePercentage: number) => {
    return round(subscription * percentage(royaltipsRatePercentage) * getSuperfansEngaged(fanbaseSize, engagementRatePercentage));
};

export const getProfessionalValue = (
    fanbaseSize: number,
    engagementRatePercentage: number,
) => {
    return getMonthlyRoyaltips(fanbaseSize, engagementRatePercentage);
};
