import ReactSelect from 'react-select';
import colors from 'styles/export/colors.module.scss';

export interface SelectValueLabel {
    value: string;
    label: string;
}

interface SelectProps {
    options: SelectValueLabel[];
    disabled?: boolean;
    placeholder?: string;
    value: string | null | undefined;
    primaryColor?: string;
    onChange: (v: string | null) => void;
}

const Dropdown = ({ options, disabled, placeholder, value, primaryColor, onChange }: SelectProps) => {
    const _primaryColor = primaryColor ?? colors.primaryGreen;

    return (
        <ReactSelect
            options={options}
            isClearable={false}
            placeholder={placeholder}
            isDisabled={disabled}
            menuPlacement="auto"
            styles={{
                control: (base, _state) => ({
                    ...base,
                    background: disabled ? '#eee' : _primaryColor,
                    border: '1px solid ' + colors.basicWhite,
                    borderRadius: '10px',
                    height: '2.5rem',
                    marginTop: '0.4rem',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                        border: '1px solid ' + colors.basicWhite,
                    }
                }),
                singleValue: (base) => ({
                    ...base,
                    color: colors.basicWhite,
                    fontWeight: 700,
                    paddingLeft: '0.8rem',
                    paddingRight: '0.4rem',
                }),
                indicatorSeparator: () => ({
                    display: 'none',
                }),
                dropdownIndicator: (base) => ({
                    ...base,
                    svg: {
                        fill: colors.basicWhite,
                    }
                }),
                option: (base, state) => ({
                    ...base,
                    color: state.isSelected ? colors.basicWhite : colors.basicBlack,
                    backgroundColor: state.isSelected ? _primaryColor : colors.basicWhite,
                    cursor: 'pointer',
                    '&:hover': {
                        color: colors.basicWhite,
                        backgroundColor: _primaryColor,
                    }
                })
            }}
            value={options.find((c) => c.value === value) ?? null}
            onChange={(val) => {
                onChange(val?.value ?? null);
            }}
            isSearchable={false}
            isMulti={false}
        />
    );
};

export default Dropdown;
