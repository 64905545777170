import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../container/Container';
import styles from './Footer.module.scss';
import DivHtml from '../divHtml/DivHtml';
import Dropdown, { type SelectValueLabel } from '../dropdown/Dropdown';
import i18n from 'common/services/I18n';
import colors from 'styles/export/colors.module.scss';
import { Link } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from 'Config';

interface Social {
    url: string;
    icon_url: string;
}

interface Props {
    colorMode: 'light' | 'dark';
    logo: JSX.Element;
}

const Footer: React.FC<Props> = ({ colorMode, logo }) => {
    const { t } = useTranslation();

    const languagesOptions: SelectValueLabel[] = Object.keys(t('languages', { returnObjects: true })).map((k) => ({
        value: k,
        label: t('languages.' + k).toUpperCase(),
    }));
    const currentLanguage = languagesOptions.find(x => x.value === i18n.language)?.value ?? DEFAULT_LANGUAGE;

    const onChangeLanguage = async (lang: string | null) => {
        if (!lang) {
            return null;
        }

        await i18n.changeLanguage(lang);
    };

    const socials: Social[] = t('footer.socials', { returnObjects: true }) ?? [];

    return (
        <div className={`${styles.container} ${colorMode === 'light' ? styles.containerLight : styles.containerDark}`}>
            <Container>
                <div className={styles.information}>
                    <div className={`${styles.column} ${styles.logoContainer}`}>
                        <div>
                            {logo}
                            <div className={styles.socialIcons}>
                                {socials.map((social, i) => (
                                    <a key={i} href={social.url} target="_blank" rel="noreferrer">
                                        <img src={social.icon_url} />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={`${styles.label} ${colorMode === 'dark' ? styles.labelDark : ''}`}>{t('footer.get_in_touch.label')}</div>
                        <DivHtml className={styles.text} html={t('footer.get_in_touch.text', { interpolation: { escapeValue: false } })} />
                    </div>
                    <div className={styles.column}>
                        <div className={`${styles.label} ${colorMode === 'dark' ? styles.labelDark : ''}`}>{t('footer.office.label')}</div>
                        <div className={styles.text}><b>{t('footer.office.area')}</b></div>
                        <DivHtml className={styles.text} html={t('footer.office.address', { interpolation: { escapeValue: false } })} />
                    </div>
                    <div className={styles.column}>
                        <div className={`${styles.label} ${colorMode === 'dark' ? styles.labelDark : ''}`}>{t('footer.legal.label')}</div>
                        <Link to="/privacy-policy" className={styles.link}>{t('footer.legal.privacy_policy')}</Link>
                        <Link to="/legal-terms" className={styles.link}>{t('footer.legal.legal_notice')}</Link>
                        <Link to="/contest-rules" className={styles.link}>{t('footer.legal.contest_rules')}</Link>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <DivHtml className={`${styles.copyrightText} ${colorMode === 'dark' ? styles.copyrightTextDark : ''}`} html={t('footer.copyright_text', { year: new Date().getFullYear(), interpolation: { escapeValue: false } })} />
                    <Dropdown
                        options={languagesOptions}
                        onChange={onChangeLanguage}
                        value={currentLanguage}
                        primaryColor={colorMode === 'light' ? colors.primaryGreen : colors.darkBackground}
                    />
                </div>
            </Container>
        </div>
    );
};

export default Footer;
