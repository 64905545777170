import Button from 'common/components/button/Button';
import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EstimateIncome.module.scss';
import colors from 'styles/export/colors.module.scss';
import IncomeArtistTab from './incomeArtistTab/IncomeArtistTab';
import IncomeProfessionalTab from './incomeProfessionalTab/IncomeProfessionalTab';
import IncomeAmbassadorTab from './incomeAmbassadorTab/IncomeAmbassadorTab';

interface Props {
    estimateTab: 'artist' | 'professional' | 'ambassador' ;
}

const EstimateIncome = (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState<'artist' | 'professional' | 'ambassador'>('artist');

    useEffect(() => {
        setTab(props.estimateTab);
    }, [props.estimateTab]);

    return (
        <Container ref={ref} className={styles.container}>
            <PageSection title={t('home.estimate_income.title')}>
                <div className={styles.innerContainer}>
                    <div className={styles.tabsButtons}>
                        <Button
                            color={colors.primaryGreen}
                            opaque={tab !== 'artist'}
                            onClick={() => { setTab('artist'); }}
                            className={styles.tabButton}
                        >
                            {t('home.estimate_income.artist.button_text').toUpperCase()}
                        </Button>
                        <Button
                            color={colors.primaryGreen}
                            opaque={tab !== 'professional'}
                            onClick={() => { setTab('professional'); }}
                            className={styles.tabButton}
                        >
                            {t('home.estimate_income.professional.button_text').toUpperCase()}
                        </Button>
                        <Button
                            color={colors.primaryGreen}
                            opaque={tab !== 'ambassador'}
                            onClick={() => { setTab('ambassador'); }}
                            className={styles.tabButton}
                        >
                            {t('home.estimate_income.ambassador.button_text').toUpperCase()}
                        </Button>
                    </div>

                    {tab === 'artist' && <IncomeArtistTab />}
                    {tab === 'professional' && <IncomeProfessionalTab />}
                    {tab === 'ambassador' && <IncomeAmbassadorTab/>}
                </div>
            </PageSection>
        </Container>
    );
};

export default forwardRef(EstimateIncome);
