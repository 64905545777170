import { SubscribeWaitListModalProvider } from 'common/components/subscribeWaitListModal/SubscribeWaitListModal';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Router from 'Router';
import Setup from 'Setup';
import i18n from './common/services/I18n';

const App: React.FunctionComponent = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Setup>
                <SubscribeWaitListModalProvider>
                    <Router />
                </SubscribeWaitListModalProvider>
            </Setup>
        </I18nextProvider>
    );
};

export default App;
