import styles from './HeaderWaves.module.scss';
import useIsMobile from 'common/hooks/useIsMobile';

interface Props {
    wavesUrl: string;
    wavesMobileUrl: string;
}

const HeaderWaves = ({ wavesUrl, wavesMobileUrl }: Props) => {
    const isMobile = useIsMobile();

    if (!isMobile) {
        return <img className={styles.waves} src={wavesUrl} />;
    }

    if (isMobile) {
        return <img className={styles.wavesMobile} src={wavesMobileUrl} />;
    }

    return null;
};

export default HeaderWaves;
