import Container from 'common/components/container/Container';
import DivHtml from 'common/components/divHtml/DivHtml';
import PageSection from 'common/components/pageSection/PageSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Ecosystem.module.scss';
import useIsMobile from 'common/hooks/useIsMobile';

const Ecosystem: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <Container>
            <PageSection title={t('home.ecosystem.title')} >
                <DivHtml
                    html={t('home.ecosystem.text', { interpolation: { escapeValue: false } })}
                    className={styles.ecosystem_description}
                />
                <div className={ `${isMobile ? styles.mobile_margin : styles.web_margin}`}>
                    <img src={isMobile ? `${t('home.ecosystem.image_mobile')}` : `${t('home.ecosystem.image_web')}`}
                        className={styles.ecosystem_image}
                    />
                </div>
            </PageSection>
        </Container>
    );
};

export default Ecosystem;
