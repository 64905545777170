import React from 'react';
import DivHtml from '../divHtml/DivHtml';
import styles from './PageSection.module.scss';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    className?: string;
    title: string;
    contentClassName?: string;
    titleClassName?: string;
    colorMode?: 'light' | 'dark';
};

const PageSection = ({ className, title, contentClassName, titleClassName, colorMode = 'light', ...props }: Props) => (
    <div
        {...props}
        className={`${styles.container} ${className ?? ''}`}
    >
        <DivHtml className={`${styles.title} ${colorMode === 'dark' ? styles.darkMode : undefined} ${titleClassName ?? ''}`} html={title}/>
        <div className={contentClassName}>
            {props.children}
        </div>
    </div>
);

export default PageSection;
