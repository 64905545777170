import useIsMobile from 'common/hooks/useIsMobile';
import React, { useMemo, useRef, useState } from 'react';
import Modal from '../modal/Modal';
import UMatchLogo from '../logo/UMatchLogo';
import styles from './SubscribeWaitListModal.module.scss';
import { useTranslation } from 'react-i18next';

export const SubscribeWaitListModalContext = React.createContext({ hide: () => { }, open: (colorMode: 'light' | 'dark') => {} });

interface SubscribeWaitListModalProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const SubscribeWaitListModalProvider = ({ children }: SubscribeWaitListModalProviderProps) => {
    const [showModal, setShowModal] = useState(false);
    const [colorMode, setColorMode] = useState<'light' | 'dark'>('light');

    return (
        <SubscribeWaitListModalContext.Provider
            value={
                useMemo(() => ({
                    hide: () => setShowModal(false),
                    open: (colorMode: 'light' | 'dark') => {
                        setColorMode(colorMode);
                        setShowModal(true);
                    }
                }), [])
            }
        >
            {children}
            <SubscribeWaitListModal
                isOpen={showModal}
                colorMode={colorMode}
                onClose={() => setShowModal(false)}
            />
        </SubscribeWaitListModalContext.Provider>
    );
};

interface Props {
    isOpen: boolean;
    colorMode: 'light' | 'dark';
    onClose: () => void;
}

const SubscribeWaitListModal = ({ isOpen, colorMode, onClose }: Props) => {
    const isMobile = useIsMobile();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);

    const iFrameUrl = t('subscribe_wait_list.iframe_url', { interpolation: { escapeValue: true } });

    const getStyles = () => {
        const defaultStyles = `
            @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

            #sib-container input:-ms-input-placeholder {
                text-align: left;
                font-family: "Poppins", sans-serif;
                color: #101010;
            }
        
            #sib-container input::placeholder {
                text-align: left;
                font-family: "Poppins", sans-serif;
                color: #101010;
            }
        
            #sib-container textarea::placeholder {
                text-align: left;
                font-family: "Poppins", sans-serif;
                color: #101010;
            }

            .sib-form {
                padding: 0 !important;
            }

            .sib-form-message-panel,
            .sib-form-block,
            .entry__error,
            .entry__label,
            .checkbox {
                font-family:'Poppins', sans-serif !important;
            }

            .sib-container--vertical {
                padding: 0 !important;
                border-width: 0 !important;
                border-radius: 0 !important;
            }
        `;

        if (colorMode === 'dark') {
            return `
                ${defaultStyles}
            `;
        }

        return `
            ${defaultStyles}
        `;
    };

    const onIframeLoad = () => {
        if (!iframeRef.current) {
            return;
        }

        const doc = iframeRef.current.contentDocument;
        if (!doc) {
            return;
        }

        const style = doc.createElement('style');
        style.textContent = getStyles();
        doc.head.append(style);

        setIsLoaded(true);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            className={styles.modal}
            colorMode={colorMode}
        >
            <div className={styles.container}>
                {isMobile && (
                    <div className={styles.logoContainer}>
                        <UMatchLogo />
                    </div>
                )}
                <div className={styles.iframeContainer}>
                    <iframe
                        ref={iframeRef}
                        src={iFrameUrl}
                        frameBorder="0"
                        scrolling="auto"
                        allowFullScreen
                        className={styles.iframe}
                        onLoad={onIframeLoad}
                        style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SubscribeWaitListModal;
