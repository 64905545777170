
/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRggUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
};

/**
* Type
*/
export type SiteType = ('artys' | 'umatch');
export const TYPE: SiteType = process.env.REACT_APP_TYPE as SiteType;

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULTNS = 'translations';
export const CURRENT_LANGUAGE_DIR = TYPE === 'umatch' ? 'umatch' : 'artys';
