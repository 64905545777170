import React, { forwardRef } from 'react';
import styles from './Container.module.scss';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    className?: string;
};

const Container = ({ className, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div {...props} className={`${styles.container} ${className ?? ''}`} ref={ref}>{props.children}</div>
);

export default forwardRef(Container);
