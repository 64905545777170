import Container from 'common/components/container/Container';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Advisors.module.scss';
import DivHtml from 'common/components/divHtml/DivHtml';
import { ReactComponent as InstagramIcon } from 'assets/svgs/instagram-icon.svg';
import PageSection from 'common/components/pageSection/PageSection';

export interface Advisor {
    name: string | null;
    role: string | null;
    description: string | null;
    image: string | null;
    company_logo: string | null;
    instagram_link: string | null;
}

interface Props {
    colorMode: 'light' | 'dark';
}

const Advisors: React.FC<Props> = ({ colorMode }) => {
    const { t } = useTranslation();
    const advisors: Advisor[] = t('home.advisors.advisors', { returnObjects: true }) ?? [];

    const isLightMode = colorMode === 'light';

    return (
            <Container >
                <PageSection title={t('home.advisors.title')} colorMode={colorMode}>
                    <div className={styles.advisorsContainer}>
                    {advisors.map((item, key) => (
                        <div
                            key={key}
                            className={`${styles.card} ${isLightMode ? styles.cardLight : styles.cardDark}`}
                        >
                            <div className={styles.cardContainer}>
                                <div className={styles.topPart}>
                                    <div className={styles.imageParent}>
                                        <img className={styles.image} src={item.image ?? '#'}></img>
                                    </div>

                                    <div className={`${styles.title}`}>{item.name}</div>
                                    <div className={`${styles.role}`}>{item.role}</div>
                                    {item.instagram_link && <div className={`${styles.icon} ${isLightMode ? styles.iconLight : null}`}>
                                        <a href={item.instagram_link ?? '#'} target="_blank" rel="noreferrer">
                                            <InstagramIcon className={styles.size}/>
                                        </a>
                                    </div>}
                                    <DivHtml className={styles.description} html={item.description ?? ''} />
                                </div>
                                {item.company_logo &&
                                    <img src={item.company_logo ?? '#'} className={styles.logo}/>
                                }
                            </div>
                        </div>
                    ))}
                    </div>
                    </PageSection>
            </Container>
    );
};

export default Advisors;
