import ReactModal from 'react-modal';
import styles from './Modal.module.scss';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-icon.svg';
import colors from 'styles/export/colors.module.scss';
import { useEffect } from 'react';
interface Props {
    isOpen: boolean;
    children: JSX.Element;
    className?: string;
    colorMode?: 'light' | 'dark';
    onClose: () => void;
}

const Modal = ({ isOpen, className, colorMode = 'light', children, onClose }: Props) => {
    const customStyles = {
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 900,
        },
    };

    useEffect(() => {
        if (isOpen) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'visible';
    }, [isOpen]);

    return (
        <ReactModal
            isOpen={isOpen}
            style={{
                ...customStyles,

            }}
            className={`${styles.content} ${colorMode === 'dark' ? styles.dark : ''} ${className ?? ''}`}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
        >
            <div className={styles.innerContainer}>
                <div className={styles.header}>
                    <CloseIcon className={`${styles.closeIcon}`} onClick={onClose} fill={colorMode === 'dark' ? colors.basicWhite : colors.basicBlack} />
                </div>
                <div className={styles.body}>
                    {children}
                </div>
            </div>
        </ReactModal>
    );
};

export default Modal;
