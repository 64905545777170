import { type MouseEventHandler } from 'react';
import colors from 'styles/export/colors.module.scss';
import styles from './Button.module.scss';

interface Props {
    color: string;
    textColor?: string,
    children: any;
    className?: string;
    opaque?: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const Button = ({ color, textColor, children, className, opaque = false, onClick }: Props) => {
    return (
        <button
            type="button"
            className={`${styles.button} ${className ?? ''}`}
            style={{
                backgroundColor: opaque ? colors.transparent : color,
                color: opaque ? color : (textColor ?? colors.basicWhite),
                border: opaque ? `2px solid ${color}` : `2px solid ${colors.transparent}`,
            }}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
