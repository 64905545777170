import React from 'react';
import { NumericFormat, type NumericFormatProps } from 'react-number-format';

const MoneyFormat: React.FC<NumericFormatProps> = ({ value, suffix, fixedDecimalScale, ...props }: NumericFormatProps) => {
    return (
        <NumericFormat
            value={value}
            displayType={props.displayType ?? 'text'}
            thousandSeparator={','}
            decimalSeparator={'.'}
            decimalScale={2}
            fixedDecimalScale={fixedDecimalScale}
            suffix={suffix}
            {...props}
        />
    );
};

export default MoneyFormat;
