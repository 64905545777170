import React from 'react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    html: string;
};

const PHtml = ({ html, ...props }: Props) => (
    <p {...props} dangerouslySetInnerHTML={{ __html: html }} />
);

export default PHtml;
