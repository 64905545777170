import React, { useRef } from 'react';
import styles from './SelectorMobile.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import DivHtml from '../divHtml/DivHtml';
import { type Data } from './SelectorModel';

interface Props {
    data: Data;
    colorMode: 'light' | 'dark';
}

const SelectorMobile: React.FC<Props> = ({ data, colorMode }: Props) => {
    const paginationRef = useRef<HTMLDivElement | null>(null);
    const isLightMode = colorMode === 'light';

    return (
        <div className={`${isLightMode ? styles.swiperContainerLight : styles.swiperContainerDark}`}>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={false}
                pagination={{
                    el: '.customPagination',
                    clickable: true
                }}
                modules={[Pagination]}
                initialSlide={0}
            >
                {data.options.map((item, ii) => (
                    <SwiperSlide
                        key={ii}
                        className={styles.mobileSlide}
                    >
                        <div className={styles.slideContainer}>
                            <div className={styles.mobileImageParent}>
                                <img className={styles.mobileImage} src={item.image}></img>
                            </div>

                            <div className={`${styles.mobileTitle} ${!isLightMode ? styles.darkTitle : undefined}`}>{item.title}</div>
                            <DivHtml className={`${styles.mobileDescription} ${!isLightMode ? styles.darkDescription : undefined}`} html={item.description} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div ref={paginationRef} className={`${styles.pagination} customPagination`}></div>
        </div>
    );
};

export default SelectorMobile;
