import React from 'react';
import styles from './RecordItem.module.scss';

interface Props {
    image: JSX.Element;
    name: string;
    info?: string | null;
    selected: boolean;
    className?: string;
    onClick: () => void;
}

const RecordItem: React.FC<Props> = ({ image, name, info, selected, className, onClick }) => {
    return (
        <div
            className={`${styles.container} ${selected ? styles.selected : ''} ${className ?? ''}`}
            onClick={onClick}
        >
            <div className={`${styles.innerContainer} ${selected ? styles.selected : ''}`}>
                <div className={styles.image}>
                    {image}
                </div>
                <div className={styles.name}>
                    {name}{info && <br />}{info}
                </div>
            </div>
        </div>
    );
};

export default RecordItem;
