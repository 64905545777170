import React, { useState } from 'react';
import styles from './SelectorWeb.module.scss';
import DivHtml from '../divHtml/DivHtml';
import { type DataOption, type Data } from './SelectorModel';
import { animated, useSpring } from '@react-spring/web';
import colors from 'styles/export/colors.module.scss';

interface ItemProps {
    item: DataOption;
    isOpen: boolean;
    isLightMode: boolean;
    isLast: boolean;
    onClick: () => void;
}

const Item = ({ item, isOpen, isLightMode, isLast, onClick }: ItemProps) => {
    const descriptionAnimation = useSpring({
        opacity: isOpen ? 1 : 0,
        overflow: 'hidden',
        maxHeight: isOpen ? '500px' : '0px',
        config: { duration: 650 },
    });

    const titleAnimation = useSpring({
        color: isOpen ? (!isLightMode ? colors.primaryYellow : colors.primaryGreen) : (!isLightMode ? colors.selectorDark : colors.darkBackground),
    });

    return (
        <div
            className={`${styles.item} ${isOpen ? (styles.openItem) : (styles.closedItem)}`}
            onClick={onClick}
            style={{
                borderBottom: isLast ? 'none' : undefined,
            }}
        >
            <animated.div
                className={`${styles.title} ${!isLightMode ? styles.titleDark : ''}`}
                style={titleAnimation}
            >
                {item.title}
            </animated.div>
            <animated.div style={descriptionAnimation}>
                <DivHtml
                    className={`${styles.description} ${!isLightMode ? styles.darkDescription : ''}`}
                    html={item.description}
                />
            </animated.div>
        </div>
    );
};

interface Props {
    data: Data;
    colorMode: 'light' | 'dark';
}

const SelectorWeb: React.FC<Props> = ({ data, colorMode }: Props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeImage = data.options[activeIndex]?.image;

    const isLightMode = colorMode === 'light';

    return (
        <div className={`${styles.grid} ${isLightMode ? styles.gridLight : ''}`}>
            <div className={styles.leftDiv}>
                <img
                    className={styles.webImage}
                    src={activeImage}
                />
            </div>
            <div className={styles.rightDiv}>
                {data.options.map((item, index) => (
                    <Item
                        key={index}
                        item={item}
                        isOpen={activeIndex === index}
                        isLightMode={isLightMode}
                        onClick={() => setActiveIndex(index)}
                        isLast={activeIndex === (index + 1)}
                    />
                ))}
            </div>
        </div>
    );
};

export default SelectorWeb;
