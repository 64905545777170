import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IncomeArtistTab.module.scss';
import colors from 'styles/export/colors.module.scss';
import RangeSlider from 'common/components/rangeSlider/RangeSlider';
import Label from 'common/components/label/Label';
import FormGrid from '../formGrid/FormGrid';
import Button from 'common/components/button/Button';
import RecordItem from './recordItem/RecordItem';
import { ReactComponent as InfoIcon } from 'assets/svgs/info-icon.svg';
import { getArtistValue, type RecordType } from '../calculator/ArtistIncomeCalculator';
import ArtistResultGraph from './artistResultGraph/ArtistResultGraph';
import { Tooltip } from 'react-tooltip';
import DivHtml from 'common/components/divHtml/DivHtml';
import useIsMobile from 'common/hooks/useIsMobile';

interface FormModel {
    fanbaseSize: number;
    streamsPerMonth: number;
    engagementRatePercentage: number;
    recordType: RecordType;
}

const IncomeArtistTab: React.FC = () => {
    const { t } = useTranslation();
    const [model, setModel] = useState<FormModel>({
        fanbaseSize: 36000,
        streamsPerMonth: 25000,
        engagementRatePercentage: 5,
        recordType: 'gold',
    });
    // const { open: openSubscribeWaitListModal } = useContext(SubscribeWaitListModalContext);
    const resultValue = getArtistValue(model.fanbaseSize, model.streamsPerMonth, model.engagementRatePercentage, model.recordType);
    const isMobile = useIsMobile();

    const onClickBuy = () => {
        window.open(t('home.estimate_income.artist.record.button_url') ?? '#');
    };

    const getRecordName = (recordType: RecordType) => {
        switch (recordType) {
            case 'gold':
                return t('home.estimate_income.artist.record_types.gold_record');
            case 'platinum':
                return t('home.estimate_income.artist.record_types.platinum_record');
            case 'diamond':
                return t('home.estimate_income.artist.record_types.diamond_record');
            default:
                return '';
        }
    };

    const recordName = getRecordName(model.recordType);

    return (
        <div>
            <FormGrid
                value={resultValue}
                valueUnit={t('home.estimate_income.currency')}
                valuePer={t('home.estimate_income.artist.record.value_per')}
                infoText={model.recordType !== 'none' ? (t('home.estimate_income.artist.record.info_text') + ' ' + recordName) : null}
                buyButton={model.recordType !== 'none'
                    ? (
                        <Button color={colors.primaryGreen} onClick={onClickBuy}>
                            {(t('home.estimate_income.artist.record.button_text') + ' ' + recordName).toUpperCase()}
                        </Button>
                    )
                    : null}
            >
                <div>
                    <DivHtml className={styles.usageNote} html={t('home.estimate_income.artist.labels.usage_note', { interpolation: { escapeValue: true } })} />
                    <Label>{t('home.estimate_income.artist.labels.fanbase_size')}</Label>
                    <RangeSlider
                        onChange={(v) => setModel((m) => ({ ...m, fanbaseSize: v }))}
                        value={model.fanbaseSize}
                        minPos={0}
                        maxPos={100}
                        min={0}
                        max={100000000}
                        minLabel="0"
                        maxLabel="100M"
                        showMinMaxLabels={true}
                        showValueLabel={true}
                    />
                    <Label>{t('home.estimate_income.artist.labels.number_streams_month')}</Label>
                    <RangeSlider
                        onChange={(v) => setModel((m) => ({ ...m, streamsPerMonth: v }))}
                        value={model.streamsPerMonth}
                        minPos={0}
                        maxPos={100}
                        min={0}
                        max={100000000}
                        minLabel="0"
                        maxLabel="100M"
                        showMinMaxLabels={true}
                        showValueLabel={true}
                    />
                    <Label>{t('home.estimate_income.artist.labels.engagement_rate')}</Label>
                    <RangeSlider
                        onChange={(v) => setModel((m) => ({ ...m, engagementRatePercentage: v }))}
                        value={model.engagementRatePercentage}
                        minPos={0}
                        maxPos={100}
                        min={0}
                        max={100}
                        minLabel="0"
                        maxLabel="100%"
                        showMinMaxLabels={true}
                        showValueLabel={true}
                        valueLabelSuffix="%"
                    />
                    <Label>
                        {t('home.estimate_income.artist.labels.have_record')}
                        <InfoIcon
                            className={styles.infoIcon}
                            data-tooltip-id="info-tooltip"
                            data-tooltip-html={t('home.estimate_income.artist.labels.record_info_tooltip', { interpolation: { escapeValue: false } })}
                        />
                        <Tooltip
                            id="info-tooltip"
                            className={styles.infoTooltip}
                            classNameArrow={styles.infoTooltipArrow}
                            place={isMobile ? 'top' : 'right'}
                        />
                    </Label>
                    <div className={styles.records}>
                        <RecordItem
                            image={<img src={t('home.estimate_income.artist.record_types.none_icon_url', { interpolation: { escapeValue: false } }) ?? ''} className={styles.recordImage}/>}
                            name={t('home.estimate_income.artist.record_types.none')}
                            selected={model.recordType === 'none'}
                            onClick={() => setModel((m) => ({ ...m, recordType: 'none' }))}
                            className={styles.recordItem}
                        />
                        <RecordItem
                            image={<img src={t('home.estimate_income.artist.record_types.gold_record_icon_url', { interpolation: { escapeValue: false } }) ?? ''} className={styles.recordImage}/>}
                            name={t('home.estimate_income.artist.record_types.gold_record')}
                            info={t('home.estimate_income.artist.record_types.gold_record_info')}
                            selected={model.recordType === 'gold'}
                            onClick={() => setModel((m) => ({ ...m, recordType: 'gold' }))}
                            className={styles.recordItem}
                        />
                        <RecordItem
                            image={<img src={t('home.estimate_income.artist.record_types.platinum_record_icon_url', { interpolation: { escapeValue: false } }) ?? ''} className={styles.recordImage}/>}
                            name={t('home.estimate_income.artist.record_types.platinum_record')}
                            info={t('home.estimate_income.artist.record_types.platinum_record_info')}
                            selected={model.recordType === 'platinum'}
                            onClick={() => setModel((m) => ({ ...m, recordType: 'platinum' }))}
                            className={styles.recordItem}
                        />
                        <RecordItem
                            image={<img src={t('home.estimate_income.artist.record_types.diamond_record_icon_url', { interpolation: { escapeValue: false } }) ?? ''} className={styles.recordImage}/>}
                            name={t('home.estimate_income.artist.record_types.diamond_record')}
                            info={t('home.estimate_income.artist.record_types.diamond_record_info')}
                            selected={model.recordType === 'diamond'}
                            onClick={() => setModel((m) => ({ ...m, recordType: 'diamond' }))}
                            className={styles.recordItem}
                        />
                    </div>
                </div>
            </FormGrid>

            <div>
                <div className={styles.title}>{t('home.estimate_income.artist.result.title') }</div>
                <ArtistResultGraph
                    fanbaseSize={model.fanbaseSize}
                    streamsPerMonth={model.streamsPerMonth}
                    engagementRatePercentage={model.engagementRatePercentage}
                    recordType={model.recordType}
                />
            </div>
        </div>
    );
};

export default IncomeArtistTab;
