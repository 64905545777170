import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TalksAboutUs.module.scss';

const TalksAboutUs: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <PageSection title={t('home.talks_about_us.title')} contentClassName={styles.content}>
                <iframe
                    src={t('home.talks_about_us.youtube_url') ?? '#'}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    className={styles.video}
                />
                <div className={styles.description}>
                    {t('home.talks_about_us.description')}
                </div>
            </PageSection>
        </Container>
    );
};

export default TalksAboutUs;
