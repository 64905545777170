import React from 'react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    html: string;
};

const DivHtml = ({ html, ...props }: Props) => (
    <div {...props} dangerouslySetInnerHTML={{ __html: html }} />
);

export default DivHtml;
