import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'common/components/pageHeader/PageHeader';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import EstimateIncome from './estimateIncome/EstimateIncome';
import Footer from 'common/components/footer/Footer';
import HowItWorks from './howItWorks/HowItWorks';
import TalksAboutUs from './talksAboutUs/TalksAboutUs';
import DiscoverUMatch from './discoverUMatch/DiscoverUMatch';
import Ecosystem from './ecosystem/Ecosystem';
import Technology from '../../common/components/technology/Technology';
import useTitle from 'common/hooks/useTitle';
import Sponsors from './sponsors/Sponsors';
import UMatchLogo from 'common/components/logo/UMatchLogo';
import Advisors from 'common/components/advisors/Advisors';

const ArtistPage: React.FC = () => {
    const { t } = useTranslation();
    const estimateIncomeRef = useRef<HTMLDivElement | null>(null);
    const [estimateTab, setEstimateTab] = useState<'artist' | 'professional' | 'ambassador'>('artist');
    useTitle(t('home.page_title'));

    const onTogglePage = () => {
        window.open(t('home.header.toggle_url', { interpolation: { escapeValue: true } }) ?? '#', '_self');
    };

    const onEstimateIncomeButtonClick = () => {
        if (estimateIncomeRef.current) {
            estimateIncomeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        let scrollInto = false;
        if (location.href.includes('estimate')) {
            setEstimateTab('artist');
            scrollInto = true;
        }
        if (location.href.includes('estimate/ambassador')) {
            setEstimateTab('ambassador');
            scrollInto = true;
        }
        if (location.href.includes('estimate/professional')) {
            setEstimateTab('professional');
            scrollInto = true;
        }
        if (estimateIncomeRef.current && scrollInto) {
            estimateIncomeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    });

    return (
        <div>
            <PageHeader
                title={t('home.header.title', { interpolation: { escapeValue: false } })}
                description={t('home.header.description', { interpolation: { escapeValue: false } })}
                colorMode="light"
                onToggle={onTogglePage}
                toggleValue={true}
                forArtistText={t('home.header.for_artist')}
                forFanText={t('home.header.for_fan')}
                logo={<UMatchLogo />}
                backgroundUrl={t('home.header.background_url', { interpolation: { escapeValue: true } })}
                backgroundMobileUrl={t('home.header.background_mobile_url', { interpolation: { escapeValue: true } })}
                buttons={[
                    <Button
                        key={'estimate-income'}
                        color={colors.primaryGreen}
                        textColor={colors.basicWhite}
                        onClick={onEstimateIncomeButtonClick}
                    >
                        {t('home.header.estimate_income_button').toUpperCase()}
                    </Button>,
                    // <Button
                    //     key={'buy-record'}
                    //     color={colors.transparent}
                    //     textColor={colors.primaryGreen}
                    //     onClick={onBuyRecordButtonClick}
                    // >
                    //     <img src={t('home.header.buy_record_button_icon_url', { interpolation: { escapeValue: true } }) ?? ''} className={styles.headerButtonIcon} />
                    //     {t('home.header.buy_record_button').toUpperCase()}
                    // </Button>
                ]}
            />

            <HowItWorks />

            <EstimateIncome ref={estimateIncomeRef} estimateTab={estimateTab}/>

            <DiscoverUMatch />

            <TalksAboutUs />

            <Ecosystem />

            <Technology colorMode="light"/>

            <Advisors colorMode="light"/>

            <Sponsors colorMode="light" />

            <Footer
                colorMode="light"
                logo={<UMatchLogo type="light" />}
            />
        </div>
    );
};

export default ArtistPage;
