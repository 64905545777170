import Container from '../container/Container';
import ToggleSwitch from '../toggleSwitch/ToggleSwitch';
import colors from 'styles/export/colors.module.scss';
import styles from './PageHeader.module.scss';
import useIsMobile from 'common/hooks/useIsMobile';
import type Button from '../button/Button';
import DivHtml from '../divHtml/DivHtml';

interface Props {
    title: string;
    description: string;
    colorMode: 'dark' | 'light';
    buttons: React.ReactElement<typeof Button>[];
    logo: JSX.Element;
    forFanText: string;
    forArtistText: string;
    backgroundUrl: string;
    backgroundMobileUrl: string;
    toggleValue: boolean;
    onToggle: () => void;
}

const PageHeader = ({ title, description, colorMode, onToggle, buttons, logo, forFanText, forArtistText, backgroundUrl, backgroundMobileUrl, toggleValue }: Props) => {
    const isMobile = useIsMobile();

    return (
        <div className={`${styles.container} ${colorMode === 'dark' ? styles.containerDark : styles.containerLight}`}>
            {colorMode === 'light' && !isMobile && <img className={styles.backgroundLight} src={backgroundUrl ?? '#'} />}
            {colorMode === 'light' && isMobile && <img className={styles.backgroundMobileLight} src={backgroundMobileUrl ?? '#'} />}

            <Container className={styles.innerContainer}>
                <div className={styles.logoContainer}>
                    {logo}
                </div>

                <div className={styles.toggleContainer}>
                    <span className={`${styles.toggleLabel} ${colorMode === 'dark' ? styles.darkMode : ''}`}>{forFanText}</span>
                    <ToggleSwitch
                        value={toggleValue}
                        onChange={onToggle}
                        color={colorMode === 'light' ? colors.primaryGreen : colors.primaryYellow}
                        className={styles.toggle}
                    />
                    <span className={`${styles.toggleLabel} ${colorMode === 'dark' ? styles.darkMode : ''}`}>{forArtistText}</span>
                </div>

                <div className={styles.titleWrapper}>
                    <div className={styles.titleContainer}>
                        <DivHtml
                            html={title}
                            className={`${styles.title} ${colorMode === 'light' ? styles.titleLight : styles.titleDark}`}
                        />
                        <DivHtml
                            html={description}
                            className={`${styles.description} ${colorMode === 'light' ? styles.descriptionLight : styles.descriptionDark}`}
                        />
                    </div>
                    {colorMode === 'dark' && !isMobile && (
                        <div className={styles.backgroundDarkContainer}>
                            <img className={styles.backgroundDark} src={backgroundUrl ?? '#'} />
                        </div>
                    )}
                </div>

                <div className={styles.headerButtonsContainer}>
                    {buttons}
                </div>

                {colorMode === 'dark' && isMobile && <div className={styles.backgroundMobileDark}><img src={backgroundMobileUrl ?? '#'} className={styles.backgroundMobileImg}/></div>}
            </Container>
        </div>
    );
};

export default PageHeader;
