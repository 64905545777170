import MoneyFormat from 'common/components/MoneyFormat/MoneyFormat';
import React from 'react';
import styles from './FormGrid.module.scss';

interface Props {
    children: JSX.Element;
    value: number;
    valueUnit: string;
    valuePer: string;
    infoText?: string | null;
    buyButton?: JSX.Element | null;
}

const FormGrid: React.FC<Props> = ({ children, value, valueUnit, valuePer, infoText, buyButton }) => (
    <div className={styles.form}>
        <div className={styles.leftSide}>
            {children}
        </div>
        <div className={styles.rightSide}>
            <div>
                <span className={styles.value}><MoneyFormat value={value} /></span>
                <span className={styles.valueUnit}>{valueUnit}</span>
                <span className={styles.valuePer}>{valuePer}</span>
            </div>
            {infoText && <span className={styles.infoText}>{infoText}</span>}
            {buyButton && <div className={styles.buyButton}>{buyButton}</div>}
        </div>
    </div>
);

export default FormGrid;
