import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const debounceMs = 250;
const mobileWidth = 992;

const useIsMobile = (): boolean => {
    const [isMobile, setIsMobile] = useState(false);

    useLayoutEffect(() => {
        const updateSize = (): void => {
            setIsMobile(window.innerWidth < mobileWidth);
        };
        window.addEventListener('resize', debounce(updateSize, debounceMs));
        updateSize();
        return (): void => { window.removeEventListener('resize', updateSize); };
    }, []);

    return isMobile;
};

export default useIsMobile;
